<template>
    <a-card>
        <a-page-header
            title='开启投放'
        />
        <div>
            <a-space>
                <base-button
                    :title="'重新获取广告主'"
                    @onClickBtn="getDataList"
                ></base-button>
                <span>最新统计时间：{{ dataDetail.lastStatisticTime || '-' }}</span>
            </a-space>
        </div>
        <div style="margin: 30px 0;">与任务品牌相匹配的广告主平台账套{{ dataDetail.matchPrincipalAccountCount || 0 }}个，投放素材满足规则的广告主平台账套{{ dataDetail.matchDeliveryAccountCount || 0 }}个</div>
        <a-table
            :loading="loading"
            :columns="columns"
            :data-source="dataList"
            :row-key="(record, index) => record.hjkAdPlatformAccountId"
            :pagination='false'
            :row-selection="rowSelection"
            :locale="{
                emptyText: '任务无合适的广告主'
            }"
        >
            <div slot="action" slot-scope="text, record">
                <base-button
                    :type="'link'"
                    :title="'查看'"
                    @onClickBtn="jumpAdvertiserPage"
                ></base-button>
            </div>
        </a-table>
        
        <div style="margin: 30px 0;">
            <base-button
                :title="'开启投放'"
                @onClickBtn="onCLickOpenRelease"
            ></base-button>
        </div>
        <div>PS：开启投放后任务<span class="red">不支持修改</span></div>
    </a-card>
</template>

<script>
import dayjs from 'dayjs'

export default {
    data() {
        return {
            id: this.$route.query.id,
            loading: false,
            columns: [
                {
                    align: 'center',
                    title: '广告主',
                    dataIndex: 'advertiserName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '品牌',
                    dataIndex: 'principalName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '平台账套',
                    dataIndex: 'adPlatformAccountName',
                    customRender(text) {
                        return text || '-'
                    }
                },
                {
                    align: 'center',
                    title: '账号余额（元）',
                    dataIndex: 'balance',
                    customRender(text) {
                        return (text ?? '') !== '' ? text : '-'
                    }
                },
                {
                    align: 'center',
                    title: '广告主素材统计',
                    scopedSlots: { customRender: 'action' }
                },
            ],
            dataList: [],
            selectedRowKeys: [],
            dataDetail: {
                lastStatisticTime: '',
                matchDeliveryAccountCount: 0,
                matchPrincipalAccountCount: 0,
            },
            actionTime: 0,
        }
    },
    computed: {
        rowSelection() {
            const { selectedRowKeys } = this;
            return {
                selectedRowKeys,
                onChange: (selectedRowKeys) => {
                    this.selectedRowKeys = selectedRowKeys
                },
                getCheckboxProps: record => ({
                    props: {
                        disabled: record.missingMaterialInfoList && record.missingMaterialInfoList.length > 0,
                    },
                })
            };
        },
    },
    created() {
        this.getDataList()
    },
    methods: {
        getDataList() {
            this.loading = true
            this.$api.core.deliverManage.getTaskMatchAdvertiser(this.id).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    let data = res.data
                    this.dataList = data.advertiserAccountRelationList || []

                    this.dataDetail = {
                        lastStatisticTime: data.lastStatisticTime,
                        matchDeliveryAccountCount: data.matchDeliveryAccountCount,
                        matchPrincipalAccountCount: data.matchPrincipalAccountCount,
                    }
                } else {
                    this.dataList = []
                    console.error(`获取列表失败${res}`)
                }
            })
        },
        onCLickOpenRelease() {
            if(this.selectedRowKeys.length < 1) {
                this.$message.error('请选择广告主')
            } else {
                let that = this
                
                this.$confirm({
                    title: '开启投放',
                    content: <div>开启投放后<span class='red'>任务不支持修改</span></div>,
                    okText: '是',
                    cancelText: '否',
                    onOk() {
                        that.onTurnOnDelivery()
                    },
                })
            }
        },
        turnOnDelivery() {
            this.$api.core.deliverManage.turnOnDelivery({
                hjkAdPlatformAccountIdList: this.selectedRowKeys,
                missionId: this.id
            }).then((res) => {
                this.loading = false
                if(res.code == 200) {
                    this.$message.success('操作成功')
                    this.$router.push({
                        path: `/taskManage`,
                    })
                } else {
                    this.$message.error(`操作失败,${res.message}`)
                }
            })
        },
        //获取时间间隔是否开否
        onTurnOnDelivery() {
            this.$api.core.deliverManage.findBriefDetailById(this.id).then((res) => {
                if(res.code == 200) {
                    let seconds = dayjs(res.data.endTime).diff(dayjs(), 'seconds')
                    if(seconds >= 3600) {
                        this.turnOnDelivery()
                    } else {
                        this.$message.error('任务执行时间＜1小时，建议调整任务周期后再开启投放')
                    }
                } else {
                    this.$message.error(`获取任务结束时间失败,${res.message}`)
                }
            })
        },
        jumpAdvertiserPage() {
            this.$router.push({
                path: `/advertiserMaterialStatistics`,
            })
        },
    }
}
</script>

<style scoped lang="less">
/deep/ .ant-page-header {
    padding-left: 0;
    padding-right: 0;
}
.row {
}
</style>